import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";

export default function WhyUs(): ReactElement {
    return (
        <section className={` relative ${styles.description}`}>
            <div className="flex items-center py-6 px-2 lg:p-16 gap-16 flex-col lg:flex-row">
                <div className="text-white max-w-[490px]">
                    <h3 className="lg:text-3xl text-2xl mb-6">
                        What Is the Camp Lejeune Lawsuit?
                    </h3>
                    <p className="lg:text-lg font-light">
                        The Camp Lejeune lawsuit seeks compensation for those
                        who lived or worked at the Marine Corps base in North
                        Carolina between 1953 and 1987 and were exposed to toxic
                        water. Over a million people were affected, causing
                        illnesses such as cancer and birth defects. The lawsuit
                        aims to hold the government accountable for inadequate
                        action and provide financial assistance to those
                        affected.
                    </p>
                </div>
                <div className="w-[300px] h-[270px] lg:w-[548px] lg:h-[465px] relative">
                    <Image
                        src="/basecamplejeune/assets/images/wasteOpt.jpg"
                        layout="fill"
                        alt="waste"
                    />
                </div>
            </div>
        </section>
    );
}
